import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl-next';
import { useQuery } from '@apollo/react-hooks';
import { Coordination } from '@atlassiansox/engagekit-ts';

import { useCallbackWithAnalytics } from '@atlaskit/analytics-next/useCallbackWithAnalytics';
import Popup from '@atlaskit/popup';
import { NotificationIndicator } from '@atlaskit/notification-indicator';
import { NotificationLogClient } from '@atlaskit/notification-log-client';
import { Notifications as AKNotifications } from '@atlaskit/atlassian-navigation';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useCoordinationClient } from '@confluence/engagement-provider';
import { SSRMouseEventWrapper, SSR_NAV_NOTIFICATIONS_METRIC } from '@confluence/browser-metrics';
import {
	GeneralShortcutListener,
	ShortcutVisualizer,
	NOTIFICATIONS_SHORTCUT,
} from '@confluence/shortcuts';
import { START_TOUCH } from '@confluence/navdex';
import { useSessionData } from '@confluence/session-data';
import { useRouteDataRef } from '@confluence/route-manager';
import { useSSRPlaceholderReplaceIdProp, LoadableLazy } from '@confluence/loadable';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import { NotificationIconSpotlight, SPOTLIGHT_MESSAGE_ID } from './NotificationIconSpotlight';
import { useRequestNotificationSpotlight } from './hooks/useRequestNotificationSpotlight';

const fabricNotificationLogUrl = '/gateway/api/notification-log';
import { AppNavigationUnifiedQuery } from './AppNavigationUnifiedQuery.graphql';

const REFRESH_INTERVAL = 60000;
const CLEAR_BADGE_TIME = 10000;

export const NotificationsContentLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-NotificationsContent" */ './NotificationsContent'))
			.NotificationsContent,
});

class EmptyNotificationLogClient extends NotificationLogClient {
	constructor() {
		super('', '');
	}

	countUnseenNotifications() {
		return Promise.resolve({ count: 0 });
	}
}

export const NotificationsComponent = memo(() => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const [isOpen, setIsOpen] = useState(false);
	const [showNotificationSpotlight, setShowNotificationSpotlight] = useState(true);
	const [isCleared, setIsCleared] = useState(false);
	const [unreadCount, setUnreadCount] = useState('');
	const routeDataRef = useRouteDataRef();
	const { isLicensed } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const coordinationClient = useCoordinationClient();
	const { isSiteAdmin, hasSpotlightBeenShown } = useRequestNotificationSpotlight();
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const isOnEditRoute = useIsEditorPage();
	const location = useLocation();

	const { data, loading } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		AppNavigationUnifiedQuery,
		{ skip: !isLicensed },
	);

	const shouldShowSpotlight = isSiteAdmin && !hasSpotlightBeenShown;

	useEffect(() => {
		if (isCleared) {
			const timeout = setTimeout(() => {
				setIsCleared(false);
			}, CLEAR_BADGE_TIME);

			return () => {
				clearTimeout(timeout);
			};
		}
	}, [isCleared]);

	const onClick = useCallbackWithAnalytics(
		() => {
			if (isOpen) {
				onClose();
				performance.clearMarks('notification.list.render.start');
			} else {
				performance.mark('notification.list.render.start');
				setIsCleared(true);
				setIsOpen(true);
			}
		},
		{
			type: 'sendUIEvent',
			data: {
				source: 'globalNavigation',
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'notifications',
				attributes: {
					selectedItemPageContext: routeDataRef.current?.routeName,
					navigationLayer: 'global',
					navVersion: '3',
					navdexPointType: START_TOUCH,
				},
			},
		},
	);

	const onMouseOverOnBell = () => {
		void NotificationsContentLoader.preload();
	};

	const focusNotificationBtn = () => {
		const notificationBtn = document.querySelector(
			"[data-testid='app-navigation-notifications-button'] button",
		) as HTMLElement;
		notificationBtn.focus();
	};

	const onClose = useCallback(() => {
		setIsOpen(false);
		focusNotificationBtn();
	}, []);

	useEffect(() => {
		if (isOpen && location && fg('confluence-frontend-close-belldrawer-on-transition')) {
			// on any transition close open notification drawer. On initial Load transitionId = 0
			onClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onClose, location]);

	const notificationLogClient = useMemo(
		() => new NotificationLogClient(fabricNotificationLogUrl, undefined),
		[],
	);

	const formatUnreadCount = (count) => {
		if (count > 9) {
			return ' (9+)';
		}
		if (count > 0) {
			return ` (${count})`;
		}
		return '';
	};

	useEffect(() => {
		notificationLogClient
			.countUnseenNotifications()
			.then((res) => {
				setUnreadCount(formatUnreadCount(res.count));
				if (res.count >= 9) {
					createAnalyticsEvent({
						type: 'sendScreenEvent',
						data: {
							name: 'unseenNotificationsOver9',
						},
					}).fire();
				}
			})
			.catch(() => {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fireNotificationSpotlightCtaClickedEvent = useCallback(
		(buttonType: 'close' | 'viewRequests') => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'requestSpotlightButton',
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'requestSpotlightButton',
					attributes: {
						buttonType,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const NotificationsBadge = useMemo(
		() => () => {
			return (
				<NotificationIndicator
					onCountUpdated={(count) => {
						setUnreadCount(formatUnreadCount(count.newCount));
					}}
					notificationLogProvider={Promise.resolve(
						isCleared || isOpen ? new EmptyNotificationLogClient() : notificationLogClient,
					)}
					refreshRate={REFRESH_INTERVAL}
				/>
			);
		},
		[isOpen, isCleared, notificationLogClient],
	);

	const renderNotificationsIcon = (triggerProps) => (
		<AKNotifications
			badge={NotificationsBadge}
			onClick={onClick}
			onMouseEnter={onMouseOverOnBell}
			isSelected={isOpen}
			tooltip={
				isLiveEditMode ||
				(isOnEditRoute && fg('confluence_frontend_edit_page_keyboard_shortcut')) ? (
					<FormattedMessage
						id="app-navigation.notifications.link"
						defaultMessage="Notifications{unreadCount}"
						values={{ unreadCount }}
					/>
				) : (
					<ShortcutVisualizer
						shortcut={NOTIFICATIONS_SHORTCUT}
						contentBefore={
							<FormattedMessage
								id="app-navigation.notifications.link"
								defaultMessage="Notifications{unreadCount}"
								values={{ unreadCount }}
							/>
						}
					/>
				)
			}
			{...triggerProps}
		/>
	);

	if (loading || !isLicensed) {
		return null;
	} else {
		const user = data?.user;

		return (
			<span
				data-testid="app-navigation-notifications"
				data-vc="app-navigation-notifications"
				{...ssrPlaceholderIdProp}
			>
				<Popup
					placement="bottom-end"
					shouldFlip
					rootBoundary="viewport"
					content={() => <NotificationsContentLoader user={user} />}
					isOpen={isOpen}
					onClose={onClose}
					shouldRenderToParent
					trigger={(triggerProps) => (
						<span data-testid="app-navigation-notifications-button">
							<SSRMouseEventWrapper metricName={SSR_NAV_NOTIFICATIONS_METRIC}>
								{shouldShowSpotlight ? (
									<SpotlightManager>
										<SpotlightTarget name="admin-request-spotlight">
											{renderNotificationsIcon(triggerProps)}
										</SpotlightTarget>
										<SpotlightTransition>
											{showNotificationSpotlight && (
												<Coordination client={coordinationClient} messageId={SPOTLIGHT_MESSAGE_ID}>
													<NotificationIconSpotlight
														onClose={async () => {
															setShowNotificationSpotlight(false);
															fireNotificationSpotlightCtaClickedEvent('close');
															await coordinationClient.stop(SPOTLIGHT_MESSAGE_ID);
														}}
														onViewRequests={async (e) => {
															e.stopPropagation();
															setShowNotificationSpotlight(false);
															setIsOpen(true);
															fireNotificationSpotlightCtaClickedEvent('viewRequests');
															await coordinationClient.stop(SPOTLIGHT_MESSAGE_ID);
														}}
													/>
												</Coordination>
											)}
										</SpotlightTransition>
									</SpotlightManager>
								) : (
									renderNotificationsIcon(triggerProps)
								)}
							</SSRMouseEventWrapper>
						</span>
					)}
				/>
				{!isLiveEditMode && !isOnEditRoute && (
					<GeneralShortcutListener
						accelerator={NOTIFICATIONS_SHORTCUT}
						listener={onClick}
						isAppNavigationShortcut
					/>
				)}
			</span>
		);
	}
});
