import type { FC, MouseEvent } from 'react';
import React, { useEffect, memo, useCallback } from 'react';
import type { WrappedComponentProps } from 'react-intl-next';
import { defineMessages, injectIntl } from 'react-intl-next';

import { Spotlight } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useCoordinationClient } from '@confluence/engagement-provider';

import { useRequestNotificationSpotlight } from './hooks/useRequestNotificationSpotlight';

export const SPOTLIGHT_MESSAGE_ID = 'confluence-admin-request-spotlight';

export interface NotificationIconSpotlightProps {
	onClose: () => void;
	onViewRequests: (e: MouseEvent<HTMLElement>) => void;
}

export const NotificationIconSpotlightComponent: FC<
	NotificationIconSpotlightProps & WrappedComponentProps
> = memo(({ onClose, onViewRequests, intl }) => {
	const { markSpotlightAsShown } = useRequestNotificationSpotlight();
	const coordinationClient = useCoordinationClient();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const messages = defineMessages({
		spotlightNotificationText: {
			id: 'app-navigation.cross-flow-using-notifications-to-improve-admin-approval.uniapa-spotlight-notification-text',
			defaultMessage:
				// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
				// eslint-disable-next-line no-restricted-syntax
				"When someone in your team requests a new product you'll get a notification here now.",
			description: 'Message that gets displayed in UNIAPA spotlight message',
		},
		spotlightCloseButtonText: {
			id: 'app-navigation.cross-flow-using-notifications-to-improve-admin-approval.uniapa-spotlight-close-button-text',
			defaultMessage: 'Close',
			description: 'Close button that gets displayed in UNIAPA spotlight message',
		},
		spotlightViewRequestsButtonText: {
			id: 'app-navigation.cross-flow-using-notifications-to-improve-admin-approval.uniapa-spotlight-view-requests-button-text',
			defaultMessage: 'View requests',
			description: 'View requests button that gets displayed in UNIAPA spotlight message',
		},
	});

	// When the spotlight is viewed, mark as shown in local storage and stop the EP message
	useEffect(() => {
		markSpotlightAsShown();
		void coordinationClient.stop(SPOTLIGHT_MESSAGE_ID);
	}, [markSpotlightAsShown, coordinationClient]);

	const fireScreenEvent = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				action: 'viewed',
				name: 'requestSpotlightInlineDialog',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	// Fire analytics event when spotlight is viewed
	useEffect(() => {
		fireScreenEvent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Spotlight
			target="admin-request-spotlight"
			key="admin-request-spotlight"
			targetRadius={100}
			dialogPlacement="bottom right"
			dialogWidth={275}
			targetBgColor={token('color.icon.inverse')}
			actions={[
				{
					onClick: (e) => onViewRequests(e),
					text: intl.formatMessage(messages.spotlightViewRequestsButtonText),
				},
				{
					onClick: onClose,
					text: intl.formatMessage(messages.spotlightCloseButtonText),
					appearance: 'subtle',
				},
			]}
		>
			{intl.formatMessage(messages.spotlightNotificationText)}
		</Spotlight>
	);
});

export const NotificationIconSpotlight = injectIntl(NotificationIconSpotlightComponent);
